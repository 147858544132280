<template>
  <div class="home" data-app>
    <NewItem
      :newDialog="newDialog"
      :area="area"
      v-on:close-dialog="closeDialog"
      v-on:save-item="saveItem"
    />
    <v-row>
      <v-col md="9">
        <v-container class="left-container" fluid>
          <v-card color="#ffffff" class="rounded-card">
            <span v-if="admin === true">
              <Inventory
                :newItemData="newItemData"
                v-if="mode === 'inventory'"
                v-on:setArea="setArea"
                :area="-1"
                :admin="admin"
              />
            </span>
            <span v-else>
              <Inventory
                :newItemData="newItemData"
                v-if="mode === 'inventory'"
                v-on:setArea="setArea"
                :area="area"
              />
            </span>

            <Settings v-if="mode === 'settings'" />
            <DataTable
              :newItemData="newItemData"
              v-else-if="mode === 'users' || mode === 'areas'"
              :mode="mode"
            />
          </v-card>
        </v-container>
      </v-col>
      <v-col md="3">
        <v-container v-if="mode === 'inventory'">
          <v-card class="menu" color="#ffffff00" elevation="0">
            <v-container class="data">
              <v-row fill-height>
                <v-col justify="center" align="right">
                  <div class="text-menu">Inventarios</div>
                </v-col>
                <v-col justify="center" align="left">
                  <v-btn color="#5a336d" fab large>
                    <v-icon color="#f3edfc" medium>
                      mdi-clipboard-text
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="admin === true && area !== -1" fill-height>
                <v-col justify="center" align="right">
                  <div class="text-menu">Nuevo inventario</div>
                </v-col>
                <v-col justify="center" align="left">
                  <v-btn color="#5a336d" fab large @click="newItem">
                    <v-icon color="#f3edfc" large>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row fill-height>
                <v-col justify="center" align="right">
                  <div class="text-menu">Cotejar inventario</div>
                </v-col>
                <v-col justify="center" align="left">
                  <v-btn color="#5a336d" fab large>
                    <v-icon color="#f3edfc" large>
                      mdi-swap-horizontal
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-container>
        <v-container
          v-else-if="
            mode === 'users' || mode === 'areas' || mode === 'settings'
          "
        >
          <v-card class="menu" color="#ffffff00" elevation="0">
            <v-container class="data">
              <v-row fill-height>
                <v-col justify="center" align="right">
                  <div class="text-menu">Ajustes</div>
                </v-col>
                <v-col justify="center" align="left">
                  <v-btn color="#5a336d" fab large @click="onChangeSettings">
                    <v-icon color="#f3edfc" large>
                      mdi-cog
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row fill-height>
                <v-col justify="center" align="right">
                  <div class="text-menu">Areas</div>
                </v-col>
                <v-col justify="center" align="left">
                  <v-btn color="#5a336d" fab large @click="onChangeAreas">
                    <v-icon color="#f3edfc" large>
                      mdi-airballoon
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row fill-height>
                <v-col justify="center" align="right">
                  <div class="text-menu">Usuarios</div>
                </v-col>
                <v-col justify="center" align="left">
                  <v-btn color="#5a336d" fab large @click="onChangeUsers">
                    <v-icon color="#f3edfc" large>
                      mdi-account-group
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row fill-height>
                <v-col justify="center" align="right">
                  <div class="text-menu">Nuevo</div>
                </v-col>
                <v-col justify="center" align="left">
                  <v-btn color="#5a336d" fab large @click="newItem">
                    <v-icon color="#f3edfc" large>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Inventory from "../components/inventory";
import NewItem from "../components/NewItem";
import DataTable from "../components/DataTable";
import Settings from "../components/Settings";
import Vue from "vue";

//const axios = require("axios").default;
//const HOST = process.env.VUE_APP_API_HOST;
let Token = require("../services/Token");

export default {
  name: "Home",
  props: {
    mode: String,
    admin: Boolean
  },
  components: {
    Inventory,
    DataTable,
    Settings,
    NewItem
  },
  data() {
    return {
      message: "",
      newDialog: false,
      newItemData: "",
      dataType: { data: "inventory", menu: "areas" },
      newDataItem: "",
      user: "",
      area: -1
    };
  },
  created() {
    let initials = Vue.localStorage.get("initials");
    this.onSendInitials(initials);
    this.init();
  },
  methods: {
    init: function() {
      if (this.$cookies.get("session") === null) {
        this.$router.push("/");
      } else {
        let isAdmin = Token.decode(
          this.$cookies.get("session").session,
          "isAdmin"
        );
        this.setArea(Token.decode(this.$cookies.get("session").session, "area"));
        this.$emit("setAdmin", isAdmin);
      }
    },
    newItem: function() {
      this.newDialog = {
        edited: true,
        mode: this.mode
      };
    },
    closeDialog: function() {
      this.newDialog.edited = false;
    },
    saveItem: function(data) {
      this.newItemData = data;
      this.newDialog.edited = false;
    },
    onSendInitials: function(user) {
      this.$emit("initials", user);
    },
    onChangeUsers: function() {
      this.$emit("mode", "users");
    },
    onChangeAreas: function() {
      this.$emit("mode", "areas");
    },
    onChangeSettings: function() {
      this.$emit("mode", "settings");
    },
    onChangeInventory: function() {
      this.$emit("mode", "inventory");
    },
    setArea: function(areaID) {
      this.area = areaID;
    }
  }
};
</script>

<style scoped>
.home {
  background-color: rgba(90, 51, 109, 0.75);
  height: 100%;
  width: 100%;
}

.v-card {
  height: 81vh;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}

.text-menu {
  color: #f3edfc;
  font-size: 2vh;
  padding-top: 10px;
}

.left-container {
  padding-top: 30px;
  padding-left: 60px;
}

.rounded-card {
  border-radius: 15px;
}
</style>
