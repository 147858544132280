
module.exports = {
  set(cookies) {
    if (cookies && !process.env.isAuth) {
      process.env.isAuth = false;
      return true;
    }
  },

  get(cookies) {
    if (cookies) {
      return true;
    } else {
      return false;
    }
  }
};
