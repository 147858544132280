<template>
  <div>
    <h1 id="message">Página no encontrada</h1>
  </div>
</template>

<script>
export default {
  name: "PageNotFound"
};
</script>

<style scoped>
#message {
  background-color: #f3edfc;
}
</style>
