<template>
  <div data-app>
    <div class="text-center">
      <v-dialog v-model="dialog" width="500">
        <v-card>
          <v-card-title class="headline grey lighten-2">
            <v-row>
              <v-col md="4">
                <div>Editar articulo</div>
              </v-col>
              <v-col align-self="end">
                <v-btn fab x-small color="#5a336d" @click="this.close">
                  <v-icon color="#f3edfc" x-small>
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="editedItem.quantity"
                      label="Cantidad"
                      hide-details="auto"
                    ></v-text-field>
                    <v-text-field
                      v-model="editedItem.code"
                      label="Código"
                    ></v-text-field>
                    <v-text-field
                      v-model="editedItem.description"
                      label="Nombre o descripción"
                    ></v-text-field>
                    <v-text-field
                      v-model="editedItem.model"
                      label="Modelo"
                    ></v-text-field>
                  </v-col>
                  <v-col> </v-col>
                </v-row>
                <v-btn block color="#5a336d" @click="edit">
                  Editar articulo
                </v-btn>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="checkedDialog" width="500">
        <v-card>
          <v-card-title class="headline grey lighten-2">
            <v-row>
              <v-col md="4">
                <div v-if="editedItem.checked === true">
                  Verificado: {{ editedItem.date }}
                </div>
                <div v-else>No Verificado</div>
              </v-col>
              <v-col align-self="end">
                <v-btn fab x-small color="#5a336d" @click="this.close">
                  <v-icon color="#f3edfc" x-small>
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-container>
                <v-row>
                  <v-col cols="4" sm="12">
                    <v-col md="8">
                      <v-text-field v-model="editedItem.code" label="Ingresa la clave de inventario"></v-text-field>
                    </v-col>
                    <v-col md="8">
                      <div class="label_bold">Departamento de resguardo</div>
                      <div class="label font-weight-medium">
                        {{ editedItem.area }}
                      </div>
                    </v-col>
                    <v-col md="8">
                      <div class="label_bold">Usuario de resguardo</div>
                      <div class="label font-weight-medium">
                        {{ editedItem.user }}
                      </div>
                    </v-col>
                    <v-col md="8">
                      <v-checkbox
                        v-model="checkObservations"
                        :label="`Observaciones`"
                      ></v-checkbox>
                      <span v-if="checkObservations === true">
                        <v-textarea :value="editedItem.comments" v-model="editedItem.comments"></v-textarea>
                      </span>
                    </v-col>
                    <v-col md="8">
                      <v-img> </v-img>
                    </v-col>
                  </v-col>
                </v-row>
                <v-btn block color="#5a336d" @click="this.onSubmitChecked">
                  Verificar
                </v-btn>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="archiveDialog" width="500">
        <v-card>
          <v-card-title>
            ¿Dar de baja el articulo?
          </v-card-title>
          <div class="archive-button">
            <v-btn
              :color="colorHoverBtn"
              @mouseover="hover = true"
              @mouseleave="hover = false"
              @click="confirmArchiveItem"
            >
              Si
            </v-btn>
          </div>
        </v-card>
      </v-dialog>
    </div>
    <v-data-table :headers="headers" :items="getData()">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>
            <v-row>
              <div class="title">Departamento / Área:</div>
              <span v-if="area === -1" class="departament">
                <v-autocomplete
                  v-model="modelArea"
                  :loading="isLoading"
                  :items="areas"
                  hide-no-data
                  hide-details="auto"
                  placeholder="Seleciona un área"
                  prepend-icon="mdi-magnify"
                >
                </v-autocomplete>
              </span>
              <span v-else class="departament">
                {{ departament }}
              </span>
            </v-row>
          </v-toolbar-title>
        </v-toolbar>
      </template>
      <template v-if="admin" v-slot:item.actions="{ item }">
        <v-speed-dial
          direction="right"
          transition="scale"
          absolute="absolute"
          class="btn-actions"
        >
          <template v-slot:activator>
            <v-btn
              color="#e5dced"
              fab
              x-small
              v-if="item.archived_date === 'N/A'"
            >
              <v-icon color="#651f70">mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-btn color="#f3edfc" fab small @click="editItem(item)">
            <v-icon color="#5a336d">
              mdi-pencil-outline
            </v-icon>
          </v-btn>
          <v-btn color="#f3edfc" fab small @click="archiveItem(item)">
            <v-icon color="#5a336d">
              mdi-archive-arrow-down
            </v-icon>
          </v-btn>
        </v-speed-dial>
      </template>
      <template v-else v-slot:item.actions="{ item }">
        <v-btn
          color="#e5dced"
          fab
          x-small
          v-if="item.checked !== true"
          @click="checkedAsset(item)"
        >
          <v-icon large color="#a7a7a7">mdi-check-circle</v-icon>
        </v-btn>
        <v-btn
          color="#e5dced"
          fab
          x-small
          v-if="item.checked === true"
          @click="checkedAsset(item)"
        >
          <v-icon large color="#651f70">mdi-check-circle</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
const axios = require("axios").default;
import Vue from "vue";
let LOG = process.env.VUE_APP_DEBUG_AUTH
  ? console.log.bind(console)
  : function() {};

export default {
  name: "inventory",
  props: {
    newItemData: {},
    area: Number,
    admin: Boolean
  },
  data: () => ({
    dialog: false,
    archiveDialog: false,
    checkedDialog: false,
    search: "",
    hover: false,
    colorHoverBtn: "#651f70",
    checkObservations: false,
    headers: [
      {
        text: "id",
        align: "start",
        filterable: false,
        value: "id"
      },
      { text: "Cantidad", value: "quantity" },
      { text: "Código", value: "code" },
      { text: "Nombre o descripción", value: "description" },
      { text: "Modelo", value: "model" },
      { text: "Fecha de Baja", value: "archived_date" },
      { text: "", value: "actions", sortable: false }
    ],
    items: [],
    editedIndex: -1,
    index: -1,
    editedItem: {
      quantity: 0,
      code: "",
      description: "",
      model: "",
      archived_date: ""
    },
    departament: "",
    modelArea: null,
    isLoading: false,
    areas: [],
    id: 0
  }),
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    newItemData() {
      this.newItemData.id = this.items.length + 1;
      this.newItem(this.newItemData);
    },
    hover: function() {
      if (this.hover) {
        this.colorHoverBtn = "#d5000a";
      } else {
        this.colorHoverBtn = "#651f70";
      }
    },
    modelArea(val) {
      for (let i = 0; i < this.itemsArea.name.length; i++) {
        if (this.itemsArea.name[i] === val) {
          this.$emit("setArea", this.itemsArea.id[i]);
          this.id = this.itemsArea.id[i];
          this.getAreas(this.itemsArea.id[i]);
          this.getAssets();
          break;
        }
      }
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.area !== -1) {
        this.id = this.area;
        this.departament = Vue.localStorage.get("area");
        this.getAssets();
      }
      this.getAreas();
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    checkedAsset(item) {
      this.editedIndex = this.items.indexOf(item);
      if (this.editedIndex.checked !== false && item.checked === false) {
        let axiosInstance = axios.create({
          headers: {
            "Same-Site": "secure",
            Authorization: `Basic ${this.$cookies.get("session").session}`
          },
          params: {
            id: this.editedItem.area_id
          }
        });
        axiosInstance
          .get(process.env.VUE_APP_API_HOST + "/areas/id")
          .then(response => {
            this.editedItem = Object.assign({}, item);
            this.editedItem.area = response.data.user.area;
            this.editedItem.user = response.data.user.name;
            this.checkedDialog = true;
          })
          .catch(err => {
            LOG(err);
          });
      }
    },
    onSubmitChecked() {
      let axiosInstance = axios.create({
        headers: {
          Authorization: `Basic ${this.$cookies.get("session").session}`
        }
      });

      axiosInstance
        .post(process.env.VUE_APP_API_HOST + "/observations/new", {
          data: this.editedItem
        })
        .then(response => {
          if (response.status === 200 && response.data === true) {
            let myItem = this.items[this.editedIndex];
            myItem.checked = true;
            this.items.splice(this.editedIndex, 1, myItem);
            this.checkedDialog = false;
          }
          else if(response.data === false) {
            this.flashMessage.show({
              status: "warning",
              title: "Mensaje",
              message: "La clave de inventario no concuerda"
            });
          }
        })
        .catch(err => {
          LOG(err);
        });
    },
    newItem(item) {
      let axiosInstance = axios.create({
        headers: {
          "Same-Site": "secure",
          Authorization: `Basic ${this.$cookies.get("session").session}`
        }
      });

      axiosInstance
        .post(process.env.VUE_APP_API_HOST + "/assets/new", {
          item: item
        })
        .then(response => {
          if (response.status === 200) {
            this.items.push(item);
          }
        })
        .catch(err => {
          LOG(err);
        });
    },
    archiveItem(item) {
      this.archiveDialog = true;
      this.editedItem = item;
    },
    confirmArchiveItem() {
      this.archiveDialog = false;
      let date = new Date();
      this.editedIndex = this.items.indexOf(this.editedItem);
      this.editedItem.archived_date = `${date.getDay()}/${date.getMonth()}/${date.getFullYear()}`;
      this.items[this.editedIndex].date = this.editedItem.archived_date;

      let axiosInstance = axios.create({
        headers: {
          "Same-Site": "secure",
          Authorization: `Basic ${this.$cookies.get("session").session}`
        }
      });

      axiosInstance
        .post(process.env.VUE_APP_API_HOST + "/assets/archive", {
          item: this.items[this.editedIndex]
        })
        .then(response => {
          if (response.status === 200) {
            this.items.splice(this.editedItem, 0, this.editedItem);
            this.items.shift();
          }
        })
        .catch(err => {
          LOG(err);
        });
    },
    close() {
      this.dialog = false;
      this.checkedDialog = false;
    },
    edit() {
      if (this.editedIndex > -1) {
        Object.assign(this.items[this.editedIndex], this.editedItem);
      } else {
        this.items.push(this.editedItem);
      }
      this.close();
    },
    getData() {
      return this.items;
    },
    getAreas() {
      let axiosInstance = axios.create({
        headers: {
          "Same-Site": "secure",
          Authorization: `Basic ${this.$cookies.get("session").session}`
        }
      });

      this.itemsArea = {};
      this.itemsArea.name = [];
      this.itemsArea.id = [];

      axiosInstance
        .get(process.env.VUE_APP_API_HOST + "/areas/all", {})
        .then(response => {
          if (response.status === 200) {
            this.data = response.data.data;
            this.data.forEach(item => {
              this.itemsArea.name.push(item.area);
              this.areas.push(item.area);
              this.itemsArea.id.push(item.id);
            });
          }
        })
        .catch(err => {
          LOG(err);
        });
    },
    getAssets() {
      const axiosInstance = axios.create({
        headers: {
          "Same-Site": "secure",
          Authorization: `Basic ${this.$cookies.get("session").session}`
        }
      });

      axiosInstance
        .get(process.env.VUE_APP_API_HOST + "/assets/id?" + "ID=" + this.id)
        .then(response => {
          if (response.status === 200) {
            this.items = response.data.data;
          } else {
            this.flashMessage.show({
              status: "error",
              title: "Error",
              message: "Algo ha salido mal."
            });
          }
        });
    }
  }
};
</script>

<style scoped>
.id {
  color: #9a61a1;
  font-weight: bold;
}

.item-background {
  background-color: #e5dced;
  border-radius: 4px;
  height: 30px;
}
.label_bold {
  font-weight: bold;
  font-size: 1.2em;
  text-align: left;
  color: #5a336d;
}

.label {
  text-align: left;
  font-size: 1em;
  color: #5a336d;
}

.item {
  padding-left: 10px;
  padding-top: 5px;
}

.title {
  color: #5a336d;
  font-weight: bold;
  padding-bottom: 10px;
  padding-left: 30px;
}

.departament {
  padding-left: 10px;
  padding-right: 20px;
  color: #9a61a1;
  font-weight: bold;
}

.card-inventory {
  padding: 20px;
}

.theme--light.v-btn {
  color: #f3edfc;
}

.v-data-table {
  padding: 20px;
}

.theme--light.v-data-table {
  background-color: rgba(243, 237, 252, 0);
}

.table-container {
  margin: 10px;
}

.btn-actions {
}

.v-speed-dial--absolute {
  margin-top: -15px;
}

.archive-button {
  padding-top: 30px;
  padding-bottom: 30px;
}
</style>
