<template>
  <div class="Avatar text-left" v-if="currentRouteName !== 'Login'">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn fab v-bind="attrs" v-on="on">
          <v-avatar color="#9a61a1" size="56">
            <span class="avatar-text">{{ initials }}</span>
          </v-avatar>
        </v-btn>
      </template>
      <v-list class="menu">
        <span v-if="this.admin === true">
          <v-list-item
            v-for="(item, index) in itemsAdmin"
            :key="index"
            text-left
          >
            <v-btn block text @click="click(index)">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-btn>
          </v-list-item>
        </span>
        <span v-if="this.admin === false">
          <v-list-item
            v-for="(item, index) in itemsUser"
            :key="index"
            text-left
          >
            <v-btn block text @click="click(index)">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-btn>
          </v-list-item>
        </span>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "Avatar",
  props: {
    initials: String,
    role: String,
    admin: Boolean
  },
  data: () => ({
    itemsAdmin: [
      { title: "Perfil" },
      {
        title: "Administrador"
      },
      {
        title: "Inventario"
      },
      { title: "Cerrar sesión" }
    ],
    itemsUser: [
      { title: "Perfil" },
      { title: "Cerrar sesión" }
    ],
    zIndex: 10
  }),
  created() {
    this.flash("Usuario / Contraseña no encontrados", "error", {
      timeout: 2000
    });
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    }
  },
  methods: {
    click(index) {
      if( this.admin === true ){
        switch (index) {
          case 1:
            this.$emit("mode", "users");
            break;
          case 2:
            this.$emit("mode", "inventory");
            break;
          case 3:
            this.$cookies.remove("session");
            this.$router.push("/");
            break;
        }
      }else{
        switch (index) {
          case 1:
            this.$cookies.remove("session");
            this.$router.push("/");
            break;
        }
      }

    }
  }
};
</script>

<style scoped>
.menu {
}
</style>
