<template>
  <div>
    <v-card class="card">
      <div>
        <div id="title">Bienvenido</div>
        <div>
          Por favor ingresa el nombre de la organización y el correo del usuario
          administrador.
        </div>
        <v-form @submit.prevent="submit" ref="form">
          <v-text-field
            v-model="data.organization"
            label="Nombre organización"
            type="email"
            required
          ></v-text-field>
          <v-text-field
            v-model="data.googleID"
            label="Cliente Google id"
            type="googleId"
          ></v-text-field>
          <v-text-field
            v-model="data.email"
            label="Correo electronico"
            type="email"
            required
          ></v-text-field>
          <v-btn v-if="data === 'testo'" type="submit" class="mr-4">Siguiente</v-btn>
        </v-form>
      </div>
    </v-card>
  </div>
</template>

<script>
const axios = require("axios").default;
let axiosInstance;

export default {
  name: "Welcome",
  data: () => ({
    data: {},
    file: ""
  }),
  methods: {
    submit() {
      axiosInstance = axios.create({
        headers: {
          "Same-Site": "secure"
        }
      });

      axiosInstance
        .post(process.env.VUE_APP_API_HOST + "/wizard", {
          data: this.data
        })
        .then(response => {
          console.log(response);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<style scoped>
.card {
  margin: 4em;
}
</style>
