<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          <v-row>
            <v-col md="4">
              <div>Editar articulo1</div>
            </v-col>
            <v-col align-self="end">
              <v-btn fab x-small color="#5a336d" @click="this.close">
                <v-icon color="#f3edfc" x-small>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-container>
              <v-row>
                <v-col>
                  <span v-if="mode === 'users'">
                    <v-text-field
                      v-model="item.name"
                      label="Nombre(s)"
                    ></v-text-field>
                    <v-text-field
                      v-model="item.lastName"
                      label="Apellido(s)"
                    ></v-text-field>
                    <v-text-field
                      v-model="item.nEmployee"
                      label="Numero de empleado"
                    ></v-text-field>
                  </span>
                  <span v-else-if="mode === 'areas'">
                    <v-text-field
                      v-model="item.area"
                      label="Área"
                    ></v-text-field>
                  </span>
                </v-col>
                <v-col v-if="mode === 'inventory'"> </v-col>
              </v-row>
              <v-btn block color="#5a336d" @click="edit">Editar articulo</v-btn>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "DialogInventory",
  props: {
    newDialog: Boolean,
    message: {}
  },
  watch: {
    message: function() {
      this.init();
      this.dialog = this.message.dialog;
      this.mode = this.message.mode;
      this.item = Object.assign({}, this.message.item);
    }
  },
  data: () => ({
    editedIndex: -1,
    dialog: false,
    item: {},
    index: -1,
    mode: String,
    employees: [],
    value: null
  }),
  methods: {
    init() {
    },
    close() {
      this.dialog = false;
      this.$emit("close-dialog", false);
    },
    edit() {
      this.close();
      this.$emit("onEdit", this.item);
    }
  }
};
</script>

<style scoped>
.theme--light.v-btn {
  color: #f3edfc;
}
</style>
