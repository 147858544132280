import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import GAuth from "vue-google-oauth2";
import VueCookies from "vue-cookies";
import VueFlashMessage from "vue-flash-message";
import VueLocalStorage from "vue-localstorage";
import vuetify from "./plugins/vuetify";

const gauthOptions = {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  scope: "profile email",
  prompt: "select_account"
};

document.title = "Control de inventarios UACH";
Vue.use(VueLocalStorage);

Vue.use(GAuth, gauthOptions);
Vue.use(VueCookies);

Vue.use(VueFlashMessage);

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  render: h => h(App),
  mode: process.env.NODE_ENV
}).$mount("#app");
