import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login";
import Home from "../views/Home";
import Welcome from "../views/Welcome";
import PageNotFound from "../views/PageNotFound";
const auth = require("../services/Auth");
let isNext = false;

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "*",
    component: PageNotFound
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/welcome",
    name: "Welcome",
    component: Welcome
  }
];

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth) && !isNext) {
    if (to.fullPath === "/home" && auth.get(Vue.$cookies.get("session"))) {
      isNext = true;
      next("/home");
    } else if (to.fullPath === "/admin") {
      next("/admin");
    } else {
      next("/");
    }
  } else {
    next();
  }
});

export default router;
