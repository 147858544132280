<template>
  <div data-app>
    <div class="text-center">
      <v-dialog v-model="archiveDialog" width="500">
        <v-card>
          <v-card-title v-if="mode === 'users'">
            ¿Eliminar usuario?
          </v-card-title>
          <v-card-title v-if="mode === 'areas'">
            ¿Eliminar área?
          </v-card-title>
          <div class="archive-button">
            <v-btn
              :color="colorHoverBtn"
              @mouseover="hover = true"
              @mouseleave="hover = false"
              @click="confirmArchiveItem"
            >
              Si
            </v-btn>
          </div>
        </v-card>
      </v-dialog>
      <EditItem :message="message" v-on:onEdit="onEdit" />
    </div>
    <v-data-table :headers="headers" :items="items" loading loading-text="Cargando... espera un momento">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>
            <div class="title">{{ title }}</div>
          </v-toolbar-title>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-speed-dial
          direction="right"
          transition="scale"
          absolute="absolute"
          class="btn-actions"
        >
          <template v-slot:activator>
            <v-btn
              color="#e5dced"
              fab
              x-small
              v-if="mode === 'users' || mode === 'areas'"
            >
              <v-icon color="#651f70">mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-btn
            color="#f3edfc"
            fab
            small
            @click="editItem(item)"
            v-if="mode !== 'users'"
          >
            <v-icon color="#5a336d">
              mdi-pencil-outline
            </v-icon>
          </v-btn>
          <v-btn color="#f3edfc" fab small @click="deleteItem(item)">
            <v-icon color="#5a336d">
              mdi-delete
            </v-icon>
          </v-btn>
        </v-speed-dial>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import EditItem from "./EditItem";
const axios = require("axios").default;
let axiosInstance;
let LOG = process.env.VUE_APP_DEBUG_AUTH ? console.log.bind(console) : function() {};

export default {
  name: "Users",
  components: {
    EditItem
  },
  props: {
    mode: String,
    newItemData: {}
  },
  data: () => ({
    dialog: false,
    archiveDialog: false,
    search: "",
    hover: false,
    colorHoverBtn: "#651f70",
    headers: [],
    items: [],
    editedIndex: -1,
    myItem: {},
    message: {},
    title: ""
  }),
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    newItemData: function() {
      this.newItemData.id = this.items.length + 1;
      this.newItem(this.newItemData);
    },
    hover: function() {
      if (this.hover) {
        this.colorHoverBtn = "#d5000a";
      } else {
        this.colorHoverBtn = "#651f70";
      }
    },
    mode: function() {
      switch (this.mode) {
        case "users":
          this.title = "Usuarios";
          this.headers = [
            { text: "Numero de empleado", value: "employee_number" },
            { text: "Nombre", value: "name" },
            { text: "Área", value: "area" },
            { text: "", value: "actions", sortable: false }
          ];

          axiosInstance = axios.create({
            headers: {
              "Same-Site": "secure",
              Authorization: `Basic ${this.$cookies.get("session").session}`
            }
          });

          axiosInstance
            .get(process.env.VUE_APP_API_HOST + "/users/all")
            .then(response => {
              this.items = response.data.data;
            });
          break;
        case "areas":
          this.title = "Áreas";
          this.headers = [
            {
              text: "id",
              align: "start",
              filterable: false,
              value: "id"
            },
            { text: "Área", value: "area" },
            { text: "", value: "actions", sortable: false }
          ];

          axiosInstance = axios.create({
            headers: {
              "Same-Site": "secure",
              Authorization: `Basic ${this.$cookies.get("session").session}`
            }
          });

          axiosInstance
            .get(process.env.VUE_APP_API_HOST + "/areas/all")
            .then(response => {
              this.items = response.data.data;
            });
          break;
      }
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      switch (this.mode) {
        case "users":
          this.title = "Usuarios";
          this.headers = [
            {
              text: "id",
              align: "start",
              filterable: false,
              value: "id"
            },
            { text: "Numero de empleado", value: "employee_number" },
            { text: "Nombre", value: "name" },
            { text: "Área", value: "area" },
            { text: "", value: "actions", sortable: false }
          ];

          axiosInstance = axios.create({
            headers: {
              "Same-Site": "secure",
              Authorization: `Basic ${this.$cookies.get("session").session}`
            }
          });

          axiosInstance
            .get(process.env.VUE_APP_API_HOST + "/users/all")
            .then(response => {
              this.items = response.data.data;
            });

          break;
      }
    },
    editItem(item) {
      this.message = { dialog: true, item: item, mode: this.mode };
    },
    onEdit(item) {
      switch (this.mode) {
        case "users":
          break;
        case "areas":
          axiosInstance
            .post(process.env.VUE_APP_API_HOST + "/areas/update", {
              user: item
            })
            .then(response => {
              if (response.status === 200) {
                Object.assign(this.items[item.id - 1], item);
              }
            })
            .catch(err => {
              LOG(err);
            });
          break;
      }
    },
    newItem(item) {
      axiosInstance = axios.create({
        headers: {
          "Same-Site": "secure",
          Authorization: `Basic ${this.$cookies.get("session").session}`
        }
      });
      switch (this.mode) {
        case "users":
          axiosInstance
            .post(process.env.VUE_APP_API_HOST + "/users/new", {
              user: item
            })
            .then(response => {
              if (response.status === 200) {
                this.items.push(item);
              }
            })
            .catch(err => {
              LOG(err);
            });
          break;
        case "areas":
          axiosInstance
            .post(process.env.VUE_APP_API_HOST + "/areas/new", {
              user: item
            })
            .then(response => {
              if (response.status === 200) {
                this.items.push(item);
              }
            })
            .catch(err => {
              LOG(err);
            });
          break;
      }
    },
    confirmArchiveItem() {
      this.archiveDialog = false;
      let index = this.items.indexOf(this.myItem);
      this.items.splice(index, 1);
      switch (this.mode) {
        case "users":
          axiosInstance = axios.create({
            headers: {
              "Same-Site": "secure",
              Authorization: `Basic ${this.$cookies.get("session")}`
            }
          });

          axiosInstance
            .delete(process.env.VUE_APP_API_HOST + "/users/", {
              data: {
                user: this.myItem
              },
              crossDomain: true
            })
            .then(response => {
              if (response.status === 200) {
                this.flashMessage.show({
                  status: "success",
                  title: "",
                  message: "El usuario ha sido eliminidado."
                });
              } else {
                this.flashMessage.show({
                  status: "error",
                  title: "Error",
                  message: "Algo ha ocurrido."
                });
              }
            });
          break;
        case "areas":
          axiosInstance = axios.create({
            headers: {
              "Same-Site": "secure"
            }
          });

          axiosInstance
            .delete(process.env.VUE_APP_API_HOST + "/areas/", {
              data: {
                id: this.myItem.id
              }
            })
            .then(response => {
              if (response.status === 200) {
                this.flashMessage.show({
                  status: "success",
                  title: "",
                  message: "El area ha sido eliminidada."
                });
              } else {
                this.flashMessage.show({
                  status: "error",
                  title: "Error",
                  message: "Algo ha ocurrido."
                });
              }
            });
          break;
      }
    },
    deleteItem(item) {
      this.archiveDialog = true;
      this.myItem = item;
    },
    close() {
      this.dialog = false;
    }
  }
};
</script>

<style scoped>
.id {
  color: #9a61a1;
  font-weight: bold;
}

.item-background {
  background-color: #e5dced;
  border-radius: 4px;
  height: 30px;
}

.item {
  padding-left: 10px;
  padding-top: 5px;
}

.title {
  color: #5a336d;
  font-weight: bold;
  padding-bottom: 10px;
  padding-left: 30px;
}

.departament {
  padding-left: 10px;
  padding-right: 20px;
  color: #9a61a1;
  font-weight: bold;
}

.card-inventory {
  padding: 20px;
}

.theme--light.v-btn {
  color: #f3edfc;
}

.v-data-table {
  padding: 20px;
}

.theme--light.v-data-table {
  background-color: rgba(243, 237, 252, 0);
}

.table-container {
  margin: 10px;
}

.btn-actions {
}

.v-speed-dial--absolute {
  margin-top: -15px;
}

.archive-button {
  padding-top: 30px;
  padding-bottom: 30px;
}
</style>
