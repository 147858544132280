<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <v-card v-if="mode === 'inventory'">
        <v-card-title class="headline grey lighten-2">
          <v-row>
            <v-col md="4">
              <div>Nuevo articulo</div>
            </v-col>
            <v-col align-self="end">
              <v-btn fab x-small color="#5a336d" @click="this.close">
                <v-icon color="#f3edfc" x-small>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container>
              <v-row>
                <v-col>
                  <span v-if="mode === 'inventory'">
                    <ValidationProvider rules="required">
                      <v-text-field
                        v-model="item.quantity"
                        placeholder="0"
                        label="Cantidad"
                        :rules="rules"
                        required
                        hide-details="auto"
                        type="number"
                      ></v-text-field>
                    </ValidationProvider>
                    <v-text-field
                      v-model="item.code"
                      label="Código"
                      placeholder="123457680"
                      :rules="rules"
                      required
                    ></v-text-field>
                    <v-text-field
                      v-model="item.description"
                      label="Nombre o descripción"
                      placeholder="Dell / Computadora"
                      :rules="rules"
                      required
                    ></v-text-field>
                    <v-text-field
                      v-model="item.model"
                      label="Modelo"
                      placeholder="FH4444"
                      :rules="rules"
                      required
                    ></v-text-field>
                  </span>
                  <span v-if="mode === 'users'">
                    <v-text-field
                      v-model="item.name"
                      label="Nombre(s)"
                      placeholder="Juan"
                    ></v-text-field>
                    <v-text-field
                      v-model="item.lastName"
                      label="Apellido(s)"
                      placeholder="Ortiz"
                    ></v-text-field>
                    <v-text-field
                      v-model="item.employee_number"
                      label="Numero empleado"
                      placeholder="12000"
                    ></v-text-field>
                  </span>
                </v-col>
                <!-- Area de para la imagen de inventario <v-col> </v-col> -->
              </v-row>
              <v-btn block color="#5a336d" @click="save" :disabled="!valid"
                >Nuevo artículo</v-btn
              >
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
      <v-card v-if="mode === 'users'">
        <v-card-title class="headline grey lighten-2">
          <v-row>
            <v-col md="4">
              <div>Usuarios</div>
            </v-col>
            <v-col align-self="end">
              <v-btn fab x-small color="#5a336d" @click="this.close">
                <v-icon color="#f3edfc" x-small>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-container>
              <v-row>
                <v-col>
                  <span v-if="mode === 'users'">
                    <v-autocomplete
                      v-model="model"
                      :loading="isLoading"
                      :items="items"
                      :search-input.sync="search"
                      hide-no-data
                      hide-details="auto"
                      placeholder="Buscar empleado"
                      prepend-icon="mdi-magnify"
                    >
                    </v-autocomplete>
                    <v-autocomplete
                      v-model="modelArea"
                      :loading="isLoading"
                      :items="areas"
                      hide-no-data
                      hide-details="auto"
                      placeholder="Buscar área"
                      prepend-icon="mdi-magnify"
                    >
                    </v-autocomplete>
                    <v-row></v-row>
                  </span>
                </v-col>
              </v-row>
              <span v-if="mode === 'users'">
                <v-btn
                  block
                  color="#5a336d"
                  @click="save"
                  :disabled="btnDisable"
                  >Nuevo empleado</v-btn
                >
              </span>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
      <v-card v-if="mode === 'areas'">
        <v-card-title class="headline grey lighten-2">
          <v-row>
            <v-col md="4">
              <div>Áreas</div>
            </v-col>
            <v-col align-self="end">
              <v-btn fab x-small color="#5a336d" @click="this.close">
                <v-icon color="#f3edfc" x-small>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" @submit.prevent="save">
            <v-container>
              <v-row>
                <v-col>
                  <span v-if="mode === 'areas'">
                    <v-text-field
                      v-model="item.area"
                      label="Área"
                    ></v-text-field>
                  </span>
                </v-col>
              </v-row>
              <v-btn block color="#5a336d" @click="save" v-if="mode === 'users'"
                >Nuevo empleado</v-btn
              >
              <v-btn
                block
                color="#5a336d"
                type="submit"
                v-if="mode === 'areas'"
                :disabled="!valid"
                >Nueva área</v-btn
              >
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ValidationProvider, extend } from "vee-validate";
let LOG = process.env.VUE_APP_DEBUG_AUTH ? console.log.bind(console) : function() {};

const axios = require("axios").default;
let axiosInstance;

extend("required", value => {
  if (value !== null || value) {
    return true;
  }

  return "Valor requerido";
});

export default {
  name: "DialogInventory",
  props: {
    newDialog: {},
    area: Number
  },
  components: {
    ValidationProvider
  },
  watch: {
    newDialog: function() {
      this.dialog = this.newDialog.edited;
      this.mode = this.newDialog.mode;
      if (this.mode === "users" || this.mode === "inventory") {
        this.getAreas();
      }
    },
    dialog: function() {
      if (this.dialog.edited === false) {
        this.$emit("close-dialog", false);
        this.items = [];
        this.item = {};
      }
    },
    search(val) {
      val && val.length > 3 && val !== this.select && this.querySelections(val);
    },
    model(val) {
      let nEmployee = val.match(/^[0-9]*/i);
      switch (this.mode) {
        case "users":
          this.data.forEach(element => {
            if (element.cve_empleado === nEmployee[0]) {
              this.item.employee_number = element.cve_empleado;
              this.item.name = element.Nombre;
              this.item.email = element.correoelec;
              this.btnDisable = false;
            }
          });
          break;
        case "areas":
          this.data.forEach(element => {
            if (element.employee_number === parseInt(nEmployee[0])) {
              this.item.name = element.name;
              this.item.employee_number = element.employee_number;
              this.item.id = element.id;
            }
          });
          break;
      }
    },
    modelArea(val) {
      for (let i = 0; i < this.itemsArea.name.length; i++) {
        if (this.itemsArea.name[i] === val) {
          this.item.area_id = this.itemsArea.id[i];
          break;
        }
      }
    }
  },
  data: () => ({
    editedIndex: -1,
    dialog: false,
    item: {},
    items: [],
    areas: [],
    itemsArea: {},
    data: {},
    dataAreas: {},
    mode: "",
    employees: [],
    isLoading: false,
    model: null,
    modelArea: null,
    search: null,
    searchArea: null,
    btnDisable: false,
    valid: true,
    rules: [value => !!value || "Valor requerido"]
  }),
  methods: {
    newItem() {
      this.dialog = true;
      this.items.pop();
    },
    close() {
      this.dialog = false;
      this.items.pop();
      this.$emit("close-dialog", false);
    },
    save() {
      this.close();
      this.item.archive = "N/A";
      this.item.area_id = this.area;
      this.$emit("save-item", this.item);
      this.item = {};
    },
    querySelections(val) {
      let axiosInstance = axios.create({
        headers: {
          "Same-Site": "secure",
        }
      });

      axiosInstance
        .get(process.env.VUE_APP_API_HOST + "/users/search", {
          params: { search: val }
        })
        .then(response => {
          if (response.data.data.length > 0) {
            this.data = response.data.data;
            this.data.forEach(item => {
              this.items.push(item.cve_empleado + " " + item.Nombre);
            });
          }
        })
        .catch(err => {
          LOG(err);
        });
    },
    getAreas() {
      let axiosInstance = axios.create({
        headers: {
          "Same-Site": "secure"
        }
      });

      this.itemsArea = {};
      this.itemsArea.name = [];
      this.itemsArea.id = [];

      axiosInstance
        .get(process.env.VUE_APP_API_HOST + "/areas/all", {})
        .then(response => {
          if (response.status === 200) {
            this.data = response.data.data;
            this.data.forEach(item => {
              this.itemsArea.name.push(item.area);
              this.areas.push(item.area);
              this.itemsArea.id.push(item.id);
            });
          }
        })
        .catch(err => {
          LOG(err);
        });
    },
    getUsers() {
      this.employees = [];
      axiosInstance = axios.create({
        headers: {
          "Same-Site": "secure"
        }
      });

      axiosInstance
        .get(process.env.VUE_APP_API_HOST + "/users/all_names")
        .then(response => {
          this.data = response.data.data;
          response.data.data.forEach(user => {
            this.employees.push(user.employee_number + " " + user.name);
          });
        });
    }
  },
  created() {
    this.itemsArea.name = [];
    this.itemsArea.id = [];
  }
};
</script>

<style scoped>
.theme--light.v-btn {
  color: #f3edfc;
}

.theme--light.v-messages {
  color: red;
}
</style>
