let jwt = require("jwt-simple");

exports.decode = function(token, type) {
  let payload;
  switch (type) {
    case "isAdmin":
      payload = jwt.decode(token, process.env.VUE_APP_TOKEN_SECRET);
      return payload.role[0].is_admin;
    case "area":
      payload = jwt.decode(token, process.env.VUE_APP_TOKEN_SECRET);
      return payload.role[0].area;
    case "initials":
      payload = jwt.decode(token, process.env.VUE_APP_TOKEN_SECRET);
      return payload.role[0].email[0];
  }
};
