<template>
  <div
    id="app"
    :style="{
      'background-image': 'url(' + backgroundImage + ')',
      'background-size': 'cover'
    }"
  >
    <v-toolbar class="toolbar" v-if="this.$route.name !== 'Welcome' || this.$route.name !== '*'">
      <v-img max-width="30px" src="./static/icono_izq_menu.png"></v-img>
      <div class="title-page">Control de inventario UACH</div>
      <v-spacer></v-spacer>
      <Avatar
        v-on:mode="setMode"
        :initials="initials"
        role="1"
        :admin="admin"
      ></Avatar>
    </v-toolbar>
    <router-view
      :mode="mode"
      :admin="admin"
      v-on:mode="setMode"
      v-on:initials="setInitials"
      v-on:setAdmin="setAdmin"
    />
    <v-footer></v-footer>
    <FlashMessage />
  </div>
</template>
<script>
import Avatar from "./components/Avatar";
import Vue from 'vue';
import FlashMessage from '@smartweb/vue-flash-message';
Vue.use(FlashMessage);
export default {
  name: "App",
  components: { Avatar },
  data() {
    return {
      index: false,
      backgroundImage: require("./static/bg1.png"),
      mode: "inventory",
      initials: "",
      admin: false
    };
  },
  watch: {
    $route: function(to) {
      if (to.path === "/") {
        this.index = false;
      } else {
        this.index = true;
      }
    }
  },
  beforeMount() {
    if (this.$route.path === "/") {
      this.index = false;
    } else {
      this.index = true;
    }
  },
  methods: {
    setMode(mode) {
      this.mode = mode;
    },
    setInitials(user) {
      this.initials = user[0];
    },
    setAdmin(isAdmin) {
      this.admin = isAdmin;
    }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #f3edfc;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #651f7096;
}

.v-sheet.v-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 6%;
}

.toolbar {
  background-color: rgba(255, 7, 0, 0.71);
}

.title-page {
  color: #5a336d;
  font-weight: bold;
  font-size: 30px;
  padding-left: 2vh;
}

.avatar-text {
  color: #f3edfc;
  font-size: x-large;
}
</style>
