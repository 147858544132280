import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#4f0f6e",
        secondary: "#b100dd",
        error: '#FF5252',
        messages: '#FF5252',
        warning: '#FFC107'
      }
    }
  }
});
