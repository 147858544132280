<template>
  <div class="Settings">
    <v-form class="card">
      <div class="title">Ajustes</div>
      <v-text-field
        v-model="app_name"
        label="Nombre organización"
      ></v-text-field>
      <v-text-field v-model="client_id" label="Google Client ID"></v-text-field>
      <v-text-field
        v-model="super_user"
        label="Usuario administrador"
      ></v-text-field>
      <v-btn color="#5a336d" large>Guardar</v-btn>
    </v-form>
  </div>
</template>

<script>
export default {
  name: "Settings.vue",
  data: () => ({
    app_name: "",
    client_id: "",
    super_user: ""
  })
};
</script>

<style scoped>
.card {
  left: 90px;
}
</style>
