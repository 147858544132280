<template>
  <div>
    <v-container
      class="action"
      v-bind:style="{ 'padding-left': spacing, 'padding-right': spacing }"
    >
      <v-card color="#9a61a1E6">
        <v-container class="containerLogin">
          <v-row>
            <v-col>
              <v-img
                class="icon"
                src="../static/icono_izq_login.png"
                max-width="140px"
              ></v-img>
            </v-col>
            <v-col>
              <v-form>
                <v-container>
                  <div class="title">Control de inventarios UACH</div>
                </v-container>
                <v-text-field
                  solo
                  dense
                  v-model="username"
                  label="Usuario/Contraseña"
                  background-color="#e5dced"
                  prepend-inner-icon="mdi-account"
                ></v-text-field>
                <v-text-field
                  solo
                  dense
                  label="Contraseña"
                  v-bind:type="this.typeText"
                  v-model="password"
                  background-color="#e5dced"
                  prepend-inner-icon="mdi-lock"
                  @click:append="showPassword"
                  v-bind:append-icon="this.iconPassword"
                ></v-text-field>
                <v-btn
                  block
                  color="#651f70"
                  coloelevation="2"
                  v-on:click="SignIn"
                  >Ingresar</v-btn
                >
                <v-btn
                  class="button"
                  block
                  color="#651f70"
                  coloelevation="2"
                  v-on:click="SignInGoogle"
                  >Google</v-btn
                >
              </v-form>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Vue from "vue";

const HOST = process.env.VUE_APP_API_HOST;
const axios = require("axios").default;
let LOG = process.env.VUE_APP_DEBUG_AUTH ? console.log.bind(console) : function() {};
let Token = require("../services/Token");

export default {
  name: "Login",
  data: () => ({
    username: "",
    password: "",
    show: false,
    onSpacing: "",
    iconPassword: "mdi-eye-off",
    typeText: "password"
  }),
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    spacing() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "0px";
        case "sm":
          return "0px";
        case "md":
          return "50px";
        case "lg":
          return "100px";
        case "xl":
          return "500px";
      }
    }
  },
  methods: {
    SignIn: function() {
      const axiosInstance = axios.create({
        headers: {
          "Same-Site": "secure",
        }
      });
      axiosInstance
        .post(HOST + "/login", {
          email: this.username,
          password: this.password,
          crossDomain: true
        })
        .then(response => {
          if (response.data.token && response.status === 200) {
            let session = {
              session: response.data.token,
              expireTimes: "4d",
              path: "/",
              domain: "",
              SameSite: "strict",
              Secure: true
            };

            this.$emit("setAdmin", Token.decode(response.data.token, "isAdmin"));
            Vue.localStorage.set("initials", Token.decode(response.data.token, "initials"));

            this.$cookies.set("session", session);
            this.$router.push("/home");
          } else {
            this.$gAuth.signOut();
            this.flashMessage.show({
              status: "warning",
              title: "Login",
              message: "Usuario o contraseña no incorrectos."
            });
          }
        })
        .catch(err => {
          this.flashMessage.show({
            status: "warning",
            title: "Login",
            message: "Algo ocurrio."
          });
          LOG(err);
        });
    },
    SignInGoogle: async function() {
      const domain = process.env.VUE_APP_EMAIL_DOMAIN;
      const googleUser = await this.$gAuth.signIn();
      const idToken = googleUser.getAuthResponse().id_token;
      const email = googleUser.getBasicProfile().getEmail();

      if (email.match(domain, "i")) {
        const axiosInstance = axios.create({
          headers: {
            "Same-Site": "secure",
          }
        });
        axiosInstance
          .post(HOST + "/login/GoogleAuth", {
            email: email,
            token: idToken,
            crossDomain: true
          })
          .then(response => {
            if (response.data.token && response.status === 200) {
              let session = {
                session: response.data.token,
                secure: true,
                sameSite: "strict"
              };

              this.$cookies.set("session", session);
              this.$router.push("/home");
            } else {
              this.$gAuth.signOut();
              this.flashMessage.show({
                status: "warning",
                title: "Login",
                message: "Usuario o contraseña no encontrados."
              });
            }
          })
          .catch(err => {
            LOG(err);
          });
      }
    },
    showPassword: function() {
      if (this.iconPassword === "mdi-eye-off") {
        this.iconPassword = "mdi-eye";
        this.typeText = "normal";
      } else {
        this.iconPassword = "mdi-eye-off";
        this.typeText = "password";
      }
    }
  }
};
</script>

<style scoped>
.login {
  position: center;
  margin-top: 20rem;
  margin-bottom: 2rem;
}

.v-card {
  width: 100%;
}

.title {
  font-size: 1.5em;
  color: #f3edfc;
  text-align: justify;
  font-kerning: none;
  width: 100%;
}

input {
  background-color: rgba(247, 238, 255, 0);
  width: 100%;
  height: 100%;
  border-color: rgba(247, 238, 255, 0);
}

.containerLogin {
  padding-right: 45px;
  padding-left: 45px;
  padding-top: 130px;
  padding-bottom: 130px;
}

.title {
  color: #f3edfc;
  font-size: 2.5vh;
  text-align: center;
  text-autospace: ideograph-alpha;
}

.action {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.theme--light.v-btn {
  color: #f3edfc;
}

.btn-border-radius {
  border-radius: 10px;
}

.icon {
  margin-left: auto;
  margin-right: auto;
}

.button {
  margin-top: 20px;
}
</style>
